.assign-courses {
  .form-select__multi-value__label {
    font-weight: 400;
  }

  .form-select__multi-value__remove {
    border-left: none !important;
    margin-left: 0 !important;
  }

  .form-select__option--is-selected {
    background-color: $white;
    color: $gray-800;

    &:active {
      background-color: $white;
    }
  }

  .search-box {
    input {
      border: 1px solid var(--vz-input-border) !important;
    }
  }
}

.choose-courses-container {
  max-height: 250px;
  overflow-y: auto;
}

.choose-courses {
  .courses-item {
    background: $black-96;
  }

  .course-format-item,
  .packages-item {
    background: $white;
  }

  .course-format-item:not(.last-format) {
    border-bottom-style: hidden !important;
  }

  th:first-child {
    padding-left: 30px;
  }

  tr {
    &:hover {
      background: $light-blue;
    }
  }

  th {
    background: $white;
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .packages-header {
    background: $light-blue;
  }

  .packages-item:not(.last-format) {
    border-bottom-style: hidden !important;
  }
}

.course-detail-image {
  max-width: 180px;
  object-fit: contain;

  img {
    width: 100%;
  }
}

.course-details--tooltip {
  .tooltip-inner {
    background-color: #fff;
    min-width: 400px;
  }

  .tooltip-arrow {
    --vz-tooltip-bg: $white;
    border-bottom-color: $white;
    color: $white;
  }

  .tooltip.show {
    opacity: 1;
  }

  .course-duration {
    top: 10px;
    left: 20px;
  }

  .course-description {
    p {
      margin-bottom: 0;
    }
  }

  .course-details-info {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.choose-courses-date-time {
  max-width: 1140px;
  overflow-x: auto;

  .courses-table-container {
    max-height: 250px;
    overflow-y: auto;

    td {
      padding-top: 0;
      padding-bottom: 0;
    }

    th {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .select2-selection {
      border: none;
    }

    .is-invalid {
      color: $red;
      &::placeholder {
        color: $red;
        opacity: 1;
      }
    }
  }

  .course-name {
    max-width: 210px;
  }

  .course-type,
  .course-category {
    max-width: 10px;
  }
}

.courses-last-step-list {
  max-height: 410px;
  overflow-y: auto;
  width: 75%;
}

.course-packages-formats {
  .active {
    border-color: $primary;
  }
}

.empty-course-package {
  height: 65vh;
}

.package-courses {
  .course-description-overflow {
    max-width: 550px;
  }
}

.course-card-container {
  min-height: 460px;
}

.package-card {
  padding: 5px 15px;
  flex: 0 0 25%;
  min-height: 374px;
}

.course-card {
  padding: 10px 15px;

  .expended-card {
    position: absolute;
    z-index: 2;
    width: calc(100% - 30px);
    min-height: calc(100% - 35px);

    .course-description {
      height: auto;
      min-height: 40px;
      max-width: fit-content;
      min-width: 100%;
    }
  }

  .course-format {
    left: 15px;
    top: 145px;
  }

  .course-description {
    height: 40px;
  }

  .course-cover {
    height: 180px;
  }

  .delete-action {
    top: 15px;
    right: 15px;
    z-index: 4;
    i {
      border-radius: 4px;
    }
  }

  .select-course {
    top: 15px;
    left: 15px;
    z-index: 2;
  }
}

.package-header {
  span {
    height: fit-content;
  }
}

.course-preview {
  @media (min-width: 578px) {
    position: sticky;
    width: 525px;
    top: 70px;
  }

  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  top: auto;
  z-index: 5;
  right: 0;
  position: fixed;
  min-width: 350px;
  width: 300px;
  height: 100vh;
  margin-top: -25px;
  margin-right: -12px;

  .course-cover {
    height: 200px;
  }

  .card {
    height: 100vh;
  }

  .preview-close {
    top: 10px;
    right: 10px;
  }
}

.course-preview-open {
  &:not(.active) {
    opacity: 0.6;
  }

  &.active {
    box-shadow: 0 21px 22px 0 rgba(115, 133, 149, 0.2);
  }
}

.form-select__menu {
  z-index: 5 !important;
}

.course-package-tabs {
  .nav-link.active {
    background: $white;
  }
}

.schedule-modal {
  .ri-close-line {
    right: 40px !important;
  }
}

.test-question-image-answer {
  border: 1px solid $gray-300;
  width: 50px;
  height: 50px;
}

.preview-course-test {
  max-height: 75vh;
  overflow-y: auto;
}

.filter-by-date {
  .ri-close-line {
    right: 30px !important;
  }
  .ri-check-fill {
    right: 38px !important;
  }
  @media screen and (max-width: 300px) {
    .ri-close-line {
      right: 5px !important;
    }
  }

  i {
    top: 30px;
  }
}

.packages-table,
.courses-table,
.assignments-table {
  overflow-x: auto;
}

.assign-step {
  border: 1px solid $blue-200;
  position: relative;
  z-index: 10;
}

.assign-course-step-icon {
  border: 1px solid $blue-200;
  background: $white;
  color: $purple;
}

.assign-course-step-icon.selected {
  border: 1px solid $green-500;
}

.assign-courses-form {
  &::after {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 5%;
    background: $blue-200;
  }
}

.selected-course-details {
  background: $light-blue;
}

.selected-course-badge {
  background: $light-blue;
  max-width: 250px;
}

.general-info {
  .title {
    min-width: 100px;
    max-width: 120px;
  }

  .zoom-meeting-link {
    max-width: 47%;
  }

  .course-cover {
    height: 200px;
  }

  .entity-title {
    color: $gray-700;
  }
}

.detail-overflow.text-truncate {
  display: block;
}

.detail-overflow.user-info {
  width: 220px;
}

#course-history-section {
  .gridjs-sort {
    min-width: 10px;
  }

  .header-name {
    width: min-content;
  }
}

#course-report-section {
  .gridjs-sort {
    min-width: 10px;
  }
}

.filter-separator:last-child {
  display: none;
}

#user-report-section {
  button.gridjs-sort {
    min-width: 10px;
  }

  .detail-overflow {
    width: 165px;
  }
}

.instructor-name {
  max-width: 150px;
  min-width: 100px;
}

.download-app-container {
  height: 100vh;
  background: $white;
}

.course-report-type {
  min-width: 250px;

  .form-select__control {
    height: 42px;
  }
}

.course-feedback-type {
  width: 200px;
  .form-select__option--is-focused:not(.form-select__option--is-selected) {
    color: $green !important;
  }
}

.report-detail-info {
  min-width: 170px;
  max-width: 210px;
}

.report-detail-info.avg-info {
  min-width: 210px;
}

.rating-total-value {
  border: 1px solid $black-96;
}

.comment--tooltip {
  .tooltip-inner {
    min-width: 360px;
  }
}

.feedback-statistics-item {
  flex: 1;
}

.filter-by-rating-select {
  width: 200px;
}

.translations-tabs {
  .nav-link:not(.active) {
    color: $dark-gray !important;
  }

  .nav-link.active {
    background: $light-blue;
  }
}

.translation-inner-tabs {
  background: $light-blue;

  .nav-link:not(.active) {
    color: $dark-gray;
  }
}

.tab-with-error {
  color: $red !important;
}
.course-info-item {
  .key {
    min-width: 100px;
  }

  .value {
    color: $dark-gray;
  }
}

.text-dark-gray {
  color: $dark-gray;
}

.answer-icon {
  i.ri-check-fill {
    border: 1px solid #1ac1bd;
  }
}

.preview-icon {
  .bg-soft-info {
    border: 1px solid #1ac1bd;
  }

  .bx-circle {
    color: #caccd8 !important;
  }
}

.answer-icon {
  i.ri-close-line {
    border: 1px solid #f25959;
  }
}
.image-answers-container.preview {
  width: 65%;
}

.image-answers-container {
  width: 40%;

  .image-container {
    width: 160px;
    height: 160px;
  }

  div.position-absolute {
    right: 0;
  }

  .rounded-circle {
    background-color: $white !important;
  }

  .bx-circle {
    color: transparent !important;
  }

  .answer-icon {
    i {
      background-color: $white !important;
    }
  }
}

.digital-course-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  .course-upload-steps {
    li {
      background: $white;
    }
  }
}

.lessons-container {
  .accordion-item {
    border-bottom: none !important;
    border-top: none !important;
  }
  .accordion-button {
    background: $light-blue !important;
    border-radius: 0.25rem !important;
  }

  .collapsed {
    background: $light-blue;
  }

  .lesson-duration path {
    fill: $text-muted;
  }

  .accordion-content {
    background: $black-87;
  }

  .accordion-item {
    margin: 10px 0;
  }

  .lesson-cover {
    object-fit: cover;
    width: 100%;
    height: auto;

    img {
      height: 100%;
      max-width: 100%;
    }
  }

  .add-lesson-icon {
    color: $blue-200;
  }

  .add-lesson-accordion-item {
    .accordion-button {
      padding: 0 !important;

      &:after {
        display: none !important;
      }
    }
  }

  .lesson-header {
    .accordion-button {
      &:after {
        display: none !important;
      }
    }

    .progress {
      @media (min-width: 768px) {
        width: 500px;
      }

      width: 200px;
    }
  }

  .lesson-progress {
    width: 95%;
    top: 50%;
  }

  .lesson-overlay {
    opacity: 0.8;
  }

  .knowledge-check-type {
    background: $black-87;
    color: $gray-700;
  }
}

.lesson-number {
  background: $blue-200;
}

#knowledgeCheckType {
  span {
    background: $white;
  }
}
