.news-card {
  min-height: 60vh;
  .news-image-container {
    position: relative;
    img {
      object-fit: cover;
      max-height: 240px;
      min-height: 240px;
      object-position: top;
    }
  }
  .card-body {
    min-height: 122px;
  }

  .actions {
    position: absolute;
    top: 15px;
    right: 15px;
    i {
      padding: 10px;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .default-image-container {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 180px !important;
      height: 26px;
    }
  }
  .item-col {
    padding: 15px;
    flex: 0 0 20%;
    @media (max-width: 1380px) {
      flex: 1 0 35%;
    }
  }

  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 230px;
  }
}

.news-manage-page,
.survey-manage-page,
.push-notification-modal {
  .ck-content {
    height: 440px;
  }

  .nav-link {
    color: $text-muted;
    &:hover {
      cursor: pointer;
    }
  }

  .nav-link.active {
    color: $purple;
  }

  .ri-close-line {
    right: 45px !important;

    &:hover {
      cursor: pointer;
    }
  }
}
